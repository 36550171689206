"use client";

import React from "react";

export default function NotFoundPage() {
  return (
    <div className="text-center">
      <div className="pt-[65px] pb-[70px] mt-[20px] max-w-[1180px] inline-block">
        <h1 className="text-2xl font-bold mt-[20px] mb-[10px] px-1">Seite konnte nicht gefunden werden</h1>
        <h2 className="text-sm mt-[28px] mb-[10px] font-light px-1">Diese Seite ist leider nicht bei uns im Programm.</h2>
      </div>
    </div>
  );
}
